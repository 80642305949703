import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Link } from 'dibs-elements/exports/Link';
import { type PdpSharedSitemapLinks_item$key as Item } from './__generated__/PdpSharedSitemapLinks_item.graphql';

import classnames from 'classnames';
import dibsCss from 'dibs-css';
import styles from './PdpSharedSitemapLinks.scss';

const itemFragment = graphql`
    fragment PdpSharedSitemapLinks_item on Item {
        sitemapLinks {
            text
            path
        }
    }
`;

export const PdpSharedSitemapLinks: FC<{ item: Item | null | undefined }> = ({ item: itemRef }) => {
    const item = useFragment(itemFragment, itemRef);
    const { sitemapLinks } = item || {};

    if (!sitemapLinks?.length) {
        return null;
    }

    return (
        <div className={dibsCss.mtLarge}>
            {sitemapLinks.map(link => {
                if (!link.path || !link.text) {
                    return null;
                }

                return (
                    <Link
                        key={link.path}
                        className={classnames(dibsCss.mx3px, styles.link)}
                        href={link.path}
                    >
                        {link.text}
                    </Link>
                );
            })}
        </div>
    );
};
