/**
 * @generated SignedSource<<7979c159fd05dbd76c54e4bcdcc1a1c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRespLinksBlock_links$data = ReadonlyArray<{
  readonly anchorText: string | null;
  readonly url: string | null;
  readonly " $fragmentType": "SharedRespLinksBlock_links";
}>;
export type SharedRespLinksBlock_links$key = ReadonlyArray<{
  readonly " $data"?: SharedRespLinksBlock_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRespLinksBlock_links">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedRespLinksBlock_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anchorText",
      "storageKey": null
    }
  ],
  "type": "InternalBuyLinkType",
  "abstractKey": null
};

(node as any).hash = "1a3b3ca9ce0eddc9a235d71394dd478d";

export default node;
