import { type FC, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import ArticleLink from './ArticleLink';

import { type EditorialModuleArticles_articles$data } from './__generated__/EditorialModuleArticles_articles.graphql';

import styles from './styles/EditorialModuleArticles.scss';

import { Button } from 'dibs-elements/exports/Button';

type Props = {
    articles: EditorialModuleArticles_articles$data;
    useWhiteBg?: boolean;
    headerClassName?: string;
};

// determine grid layout cols as described in GROWTH-6730
const getColsCount = (articles: EditorialModuleArticles_articles$data): number => {
    if (articles.length < 5) {
        return articles.length;
    }

    if (articles.length < 7) {
        return 3;
    }

    return 4;
};

const EditorialModuleArticles: FC<Props> = ({ articles, useWhiteBg, headerClassName }) => {
    const [expanded, setExpanded] = useState(false);

    // more articles gives us "stacked" article content layout
    const stacked = articles.length > 2;

    // put articles into a css grid
    const colsCount = getColsCount(articles);
    const articlesGrid = (
        <div
            className={classnames(styles.articlesWrapper, {
                [styles.col1]: colsCount === 1,
                [styles.col2]: colsCount === 2,
                [styles.col3]: colsCount === 3,
                [styles.col4]: colsCount === 4,
            })}
        >
            {articles.map((article, index) => {
                // hide article if not "view more" and not first row
                const firstRow = index + 1 <= colsCount;
                const hidden = !expanded && !firstRow;

                return (
                    <ArticleLink
                        key={index}
                        article={article}
                        index={index}
                        stacked={stacked}
                        hidden={hidden}
                    />
                );
            })}
        </div>
    );

    return (
        <HeadingLevel>
            {Heading => (
                <div
                    className={classnames(styles.container, { [styles.useWhiteBg]: !!useWhiteBg })}
                >
                    <Heading className={`${styles.sectionHeader} ${headerClassName}`}>
                        <FormattedMessage
                            defaultMessage="Read More"
                            id="dbl.editorialModule.header"
                        />
                    </Heading>

                    {articlesGrid}

                    {articles.length > colsCount && (
                        <Button
                            type="secondary"
                            size="medium"
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className={styles.viewMoreButton}
                        >
                            {expanded ? (
                                <FormattedMessage
                                    id="dbl.editorialModule.viewLess"
                                    defaultMessage="View Less"
                                />
                            ) : (
                                <FormattedMessage
                                    id="dbl.editorialModule.viewMore"
                                    defaultMessage="View More"
                                />
                            )}
                        </Button>
                    )}
                </div>
            )}
        </HeadingLevel>
    );
};

export default createFragmentContainer(EditorialModuleArticles, {
    articles: graphql`
        fragment EditorialModuleArticles_articles on WordpressArticleType @relay(plural: true) {
            publishedDate
            ...ArticleLink_article
        }
    `,
});
