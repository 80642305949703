import { type FC, type MouseEventHandler } from 'react';
import { useFragment, graphql } from 'react-relay';
import { LINKS_BLOCK_DATA_TN } from '../../constants/sbConstants';
import { moreWaysToBrowseHeader } from '../../shared/sharedMessages';

// components
import { Link } from 'dibs-elements/exports/Link';
import { Badge } from 'dibs-elements/exports/Badge';
import { Header } from 'dibs-elements/exports/Header';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { SharedPopularSearchesLink } from '../SharedPopularSearchesLink/SharedPopularSearchesLink';
import { PdpSharedSitemapLinks } from '../../pdp/PdpSharedSitemapLinks/PdpSharedSitemapLinks';

// styles
import styles from './SharedRespLinksBlock.scss';

// types
import { type SharedRespLinksBlock_item$key as Item } from './__generated__/SharedRespLinksBlock_item.graphql';
import { type SharedRespLinksBlock_links$key as Links } from './__generated__/SharedRespLinksBlock_links.graphql';
import { type SharedRespLinksBlock_viewer$key as Viewer } from './__generated__/SharedRespLinksBlock_viewer.graphql';
import { type SharedRespLinksBlock_itemSearch$key as ItemSearch } from './__generated__/SharedRespLinksBlock_itemSearch.graphql';

const MIN_LINK_COUNT = 3;

const linksFragment = graphql`
    fragment SharedRespLinksBlock_links on InternalBuyLinkType @relay(plural: true) {
        url
        anchorText
    }
`;
const itemFragment = graphql`
    fragment SharedRespLinksBlock_item on Item {
        popularSearchesLink {
            ...SharedPopularSearchesLink_popularSearchesLink
        }
        sitemapLinks {
            text
        }
        ...PdpSharedSitemapLinks_item
    }
`;
const itemSearchFragment = graphql`
    fragment SharedRespLinksBlock_itemSearch on ItemSearchQueryConnection {
        popularSearchesLink {
            ...SharedPopularSearchesLink_popularSearchesLink
        }
    }
`;
const viewerFragment = graphql`
    fragment SharedRespLinksBlock_viewer on Viewer {
        isPopularSearchesEnabled: featureFlag(feature: "popularSearches")
    }
`;

export const SharedRespLinksBlock: FC<{
    viewer: Viewer;
    title?: string;
    item: Item | null;
    links: Links | null;
    anchorTextPrefix?: string;
    onClick?: MouseEventHandler;
    itemSearch: ItemSearch | null;
    popularSearchTermClassName?: string;
}> = ({
    item: itemRef,
    links: linksRef,
    anchorTextPrefix,
    viewer: viewerRef,
    onClick = () => {},
    itemSearch: itemSearchRef,
    popularSearchTermClassName,
    title = moreWaysToBrowseHeader,
}) => {
    const item = useFragment(itemFragment, itemRef);
    const links = useFragment(linksFragment, linksRef);
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const validLinks = (links || []).filter(link => link && link.url && link.anchorText);
    const source = itemSearch || item;

    if (!source || validLinks.length < MIN_LINK_COUNT) {
        return null;
    }

    const { isPopularSearchesEnabled } = viewer;
    const { popularSearchesLink } = source || {};
    // If sitemap links exist, don't show popularSearchesLink (PDP_SEO_SITEMAP_LINKS feature flag is handled in graphql)
    // https://1stdibs.atlassian.net/browse/GROWTH-7869
    const showPopularSearchesLink = !item?.sitemapLinks?.length;

    return (
        <HeadingLevel>
            {Heading => (
                <div className={styles.wrapper} data-tn={LINKS_BLOCK_DATA_TN}>
                    <Header title={title} htmlElementType={Heading} />
                    <div className={styles.links}>
                        {validLinks.map(({ anchorText, url }) => {
                            return (
                                <Link
                                    key={url}
                                    href={url as string}
                                    dataTn={`${LINKS_BLOCK_DATA_TN}-item`}
                                    onClick={onClick}
                                    className={`${styles.link} ${styles.specify}`}
                                >
                                    <Badge type="pill" size="medium-regular" interactive>
                                        {anchorTextPrefix
                                            ? `${anchorTextPrefix} ${anchorText}`
                                            : anchorText}
                                    </Badge>
                                </Link>
                            );
                        })}
                    </div>

                    {isPopularSearchesEnabled && showPopularSearchesLink && (
                        <SharedPopularSearchesLink
                            popularSearchesLink={popularSearchesLink}
                            popularSearchTermClassName={popularSearchTermClassName}
                        />
                    )}

                    <PdpSharedSitemapLinks item={item} />
                </div>
            )}
        </HeadingLevel>
    );
};
