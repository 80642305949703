/**
 * @generated SignedSource<<0b5b0e750b62960bfc00eae365046da1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditorialModuleArticles_articles$data = ReadonlyArray<{
  readonly publishedDate: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleLink_article">;
  readonly " $fragmentType": "EditorialModuleArticles_articles";
}>;
export type EditorialModuleArticles_articles$key = ReadonlyArray<{
  readonly " $data"?: EditorialModuleArticles_articles$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorialModuleArticles_articles">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EditorialModuleArticles_articles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLink_article"
    }
  ],
  "type": "WordpressArticleType",
  "abstractKey": null
};

(node as any).hash = "f8f08a73de896d9a4e8cb43e5ed705d8";

export default node;
