import { type FC } from 'react';
import { trackEvent } from 'dibs-tracking';
import { useFragment, graphql } from 'react-relay';

// components
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

// styles
import styles from './SharedPopularSearchesLink.scss';

// types
import { type SharedPopularSearchesLink_popularSearchesLink$key as PopularSearchesLink } from './__generated__/SharedPopularSearchesLink_popularSearchesLink.graphql';

const popularSearchesLinkFragment = graphql`
    fragment SharedPopularSearchesLink_popularSearchesLink on LinkData {
        text
        path
    }
`;
export const SharedPopularSearchesLink: FC<{
    isTopPlacement?: boolean;
    popularSearchTermClassName?: string;
    popularSearchesLink: PopularSearchesLink;
}> = ({
    isTopPlacement,
    popularSearchTermClassName,
    popularSearchesLink: popularSearchesLinkRef,
}) => {
    const popularSearchesLink = useFragment(popularSearchesLinkFragment, popularSearchesLinkRef);

    if (!popularSearchesLink?.text || !popularSearchesLink?.path) {
        return null;
    }

    const { text, path } = popularSearchesLink;

    return (
        <div className={styles.popularSearches}>
            <Link
                href={path}
                dataTn="popular-searches-link"
                onClick={() =>
                    trackEvent({
                        category: 'promo interaction',
                        action: `mwtb popular searches link click${isTopPlacement ? ' - top' : ''}`,
                        label: `View All Popular ${text} Searches`,
                    })
                }
            >
                <FormattedMessage
                    id="SharedPopularSearchesLink.linkText"
                    defaultMessage="View All Popular <span>Category</span> Searches"
                    values={{
                        span: () => (
                            <span
                                className={
                                    popularSearchTermClassName || styles.popularSearchesCategory
                                }
                            >
                                {text}
                            </span>
                        ),
                    }}
                />
            </Link>
        </div>
    );
};
