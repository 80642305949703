/**
 * @generated SignedSource<<07644a9b1962f340146d49fffac0d238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleLink_article$data = {
  readonly imageUrl: {
    readonly full: string;
  };
  readonly link: string;
  readonly source: string;
  readonly subtitle: string;
  readonly title: string;
  readonly " $fragmentType": "ArticleLink_article";
};
export type ArticleLink_article$key = {
  readonly " $data"?: ArticleLink_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleLink_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleLink_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WordpressArticleImageType",
      "kind": "LinkedField",
      "name": "imageUrl",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "full",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    }
  ],
  "type": "WordpressArticleType",
  "abstractKey": null
};

(node as any).hash = "05bacc7c6c5f54ebb016a6303f788093";

export default node;
